( function() {
    'use strict';

    var settings = {
        target: '.mod-loginform'
    };

    $(function() {
        $('.show-plain-text').on('click', function() {
            $('#customer-password').attr('type', 'text');
            $( this ).siblings( '.hide-plain-text' ).show();
            $( this ).hide();
        });
        $('.hide-plain-text').on('click', function() {
            $('#customer-password').attr('type', 'password');
            $( this ).siblings( '.show-plain-text' ).show();
            $( this ).hide();
        });

        $('.password-help').on('click', function() {

            var infoBox = $( this ).parent().find('.info-body');
            if ('none' === infoBox.css('display')) {
                infoBox.show();
                $( this ).find('.tg-opened').hide();
                $( this ).find('.tg-closed').show();
                $( this ).parent().css('z-index', '100');
            } else {
                infoBox.hide();
                $( this ).find('.tg-opened').show();
                $( this ).find('.tg-closed').hide();
                $( this ).parent().css('z-index', '0');
            }
        });

        $('#gigakombi-login-btn').on('click', function() {

            var msisdn = $('#vf-number').val().trim();
            var kundenkennwort = $('#customer-password').val().trim();
            var spinner = $('#login-loading-indicator');
            var button = $( this );
            var messageBox = $('#error-message-box');
            var messageText = $('#error-message-txt');

            messageText.text('');
            messageBox.hide();

            if ('' === msisdn) {
                messageText.text(errorMessages.noPhonenumber);
                messageBox.show();
            } else if ('' === kundenkennwort) {
                messageText.text(errorMessages.noPassword);
                messageBox.show();
            } else {
                button.addClass('btn-disabled');
                spinner.show();
                var ecommerce = new vf.Ecommerce(jQeCommmerceFactory);
                ecommerce.authentificate(msisdn, kundenkennwort).then(function() {
                    ecommerce.getNBA().then(function() {
                        $.when( ecommerce.isCustomerRedPlusEligable(), ecommerce.hasCustomerNoFF() )
                    .done(function() {
                        msisdn = ecommerce.getMSISDN();
                        var script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.src = 'https://vfd2dyn.vodafone.de/crypt/serviceaio.php?msisdn2=' + msisdn;
                        document.body.appendChild(script);
                        button.removeClass('btn-disabled');
                        spinner.hide();
                    })
                    .fail(function(response) {
                        if ('404.2' === response.status) {
                            messageText.text(errorMessages.fuf);
                            messageBox.show();
                            button.removeClass('btn-disabled');
                            spinner.hide();
                        } else {
                            window.location.href = 'https://zuhauseplus-vf-test1.vfd2-testnet.de/gigakombi/nicht-berechtigt.html';
                        }
                    });
                        ecommerce.logout();
                    });

                }).fail(function() {
                    messageText.text(errorMessages.notEligable);
                    messageBox.show();
                    button.removeClass('btn-disabled');
                    spinner.hide();
                });
            }

        });
    });

    var init = function () {};
    vf.loginform = {
        settings: settings,
        init: init
    };

}(vf));
